import "bootstrap-daterangepicker"
import "select2"
class Engage {
    constructor( settings ) {
        this.lists = settings.lists
        this.csrf = settings.csrf
        this.update = settings.update
        this.init()
    }

    init () {
        this.cacheDOM()
        this.bindEvents()
        this.initAppointmentDateRange()
        this.initSelect2()
        this.initDatatable()
    }

    cacheDOM () {
        this.$leads_table = $( "#leads_table" )
        this.$body = $( "body" )
    }

    bindEvents () {
        this.$body.on( "change", ".published", this.change_published_status.bind( this ) )
    }

    initSelect2 () {
        const _SELF = this

        $( "#dispo" )
            .select2( {
                allowClear: true,
                theme: "bootstrap-5",
                width: "100%",
                placeholder: "Filter By Dispo",
            } )
            .on( "select2:select", function ( e ) {
                _SELF.$table.draw()
            } )
    }

    initAppointmentDateRange () {
        const _SELF = this

        let start = moment().hours( 0 ).minutes( 0 ).seconds( 1 ).milliseconds( 0 ),
            end = moment().hours( 23 ).minutes( 59 ).seconds( 59 ).milliseconds( 0 ),
            quarter = moment().quarter()

        $( "#datepicker" )
            .daterangepicker(
                {
                    timePicker: true,
                    startDate: start,
                    endDate: end,
                    ranges: {
                        Today: [
                            moment()
                                .hours( 0 )
                                .minutes( 0 )
                                .seconds( 1 )
                                .milliseconds( 0 ),
                            moment()
                                .hours( 23 )
                                .minutes( 59 )
                                .seconds( 59 )
                                .milliseconds( 0 ),
                        ],
                        Yesterday: [
                            moment()
                                .subtract( 1, "days" )
                                .hours( 0 )
                                .minutes( 0 )
                                .seconds( 1 )
                                .milliseconds( 0 ),
                            moment()
                                .subtract( 1, "days" )
                                .hours( 23 )
                                .minutes( 59 )
                                .seconds( 59 )
                                .milliseconds( 0 ),
                        ],
                        "Last 7 Days": [
                            moment()
                                .subtract( 6, "days" )
                                .hours( 0 )
                                .minutes( 0 )
                                .seconds( 1 )
                                .milliseconds( 0 ),
                            moment()
                                .hours( 23 )
                                .minutes( 59 )
                                .seconds( 59 )
                                .milliseconds( 0 ),
                        ],
                        "Last 30 Days": [
                            moment()
                                .subtract( 29, "days" )
                                .hours( 0 )
                                .minutes( 0 )
                                .seconds( 1 )
                                .milliseconds( 0 ),
                            moment()
                                .hours( 23 )
                                .minutes( 59 )
                                .seconds( 59 )
                                .milliseconds( 0 ),
                        ],
                        "This Week": [
                            moment()
                                .startOf( "week" )
                                .hours( 0 )
                                .minutes( 0 )
                                .seconds( 1 )
                                .milliseconds( 0 ),
                            moment()
                                .endOf( "week" )
                                .hours( 23 )
                                .minutes( 59 )
                                .seconds( 59 )
                                .milliseconds( 0 ),
                        ],
                        "This Month": [
                            moment()
                                .startOf( "month" )
                                .hours( 0 )
                                .minutes( 0 )
                                .seconds( 1 )
                                .milliseconds( 0 ),
                            moment()
                                .endOf( "month" )
                                .hours( 23 )
                                .minutes( 59 )
                                .seconds( 59 )
                                .milliseconds( 0 ),
                        ],
                        "Last Month": [
                            moment()
                                .subtract( 1, "month" )
                                .startOf( "month" )
                                .hours( 0 )
                                .minutes( 0 )
                                .seconds( 1 )
                                .milliseconds( 0 ),
                            moment()
                                .subtract( 1, "month" )
                                .endOf( "month" )
                                .hours( 23 )
                                .minutes( 59 )
                                .seconds( 59 )
                                .milliseconds( 0 ),
                        ],
                        "Current Quarter": [
                            moment()
                                .quarter( quarter )
                                .startOf( "quarter" )
                                .hours( 0 )
                                .minutes( 0 )
                                .seconds( 1 )
                                .milliseconds( 0 ),
                            moment()
                                .quarter( quarter )
                                .endOf( "quarter" )
                                .hours( 23 )
                                .minutes( 59 )
                                .seconds( 59 )
                                .milliseconds( 0 ),
                        ],
                        "Last Quarter": [
                            moment()
                                .subtract( 1, "quarter" )
                                .startOf( "quarter" )
                                .hours( 0 )
                                .minutes( 0 )
                                .seconds( 1 )
                                .milliseconds( 0 ),
                            moment()
                                .subtract( 1, "quarter" )
                                .endOf( "quarter" )
                                .hours( 23 )
                                .minutes( 59 )
                                .seconds( 59 )
                                .milliseconds( 0 ),
                        ],
                        "This Year": [
                            moment()
                                .startOf( "year" )
                                .hours( 0 )
                                .minutes( 0 )
                                .seconds( 1 )
                                .milliseconds( 0 ),
                            moment()
                                .endOf( "year" )
                                .hours( 23 )
                                .minutes( 59 )
                                .seconds( 59 )
                                .milliseconds( 0 ),
                        ],
                    },
                },
                _SELF.cb
            )
            .on( "apply.daterangepicker", function ( ev, picker ) {
                let startDate = picker.startDate.format( "YYYY-MM-DD HH:mm:ss" )
                let endDate = picker.endDate.format( "YYYY-MM-DD HH:mm:ss" )
                $( "#datepicker .filter_by_range" )
                    .val( startDate + "/" + endDate )
                    .trigger( "change" )

                _SELF.$table.draw()
            } )

        _SELF.cb( start, end )
    }

    cb ( start, end ) {
        $( "#datepicker span" ).html(
            `${ start.format( "MMMM D, YYYY hh:mm A" ) } - ${ end.format(
                "MMMM D, YYYY hh:mm A"
            ) }`
        )
        $( "#datepicker .filter_by_range" )
            .val(
                `${ start.format( "YYYY-MM-DD HH:mm:ss" ) }/${ end.format(
                    "YYYY-MM-DD HH:mm:ss"
                ) }`
            )
            .trigger( "change" )
    }

    initDatatable () {
        const SELF = this

        this.$table = this.$leads_table.DataTable( {
            ajax: {
                url: SELF.lists,
                data: function ( data ) {
                    data._method = "PATCH"
                    $( ".filters input, .filters select" ).each( function () {
                        let name = $( this ).attr( "name" ),
                            value =
                                $( this ).attr( "type" ) == "checkbox"
                                    ? $( this ).is( ":checked" )
                                        ? $( this ).val()
                                        : 0
                                    : $( this ).val()

                        data[ name ] = value
                    } )
                },
            },
            columns: [
                {
                    data: "sn",
                },
                {
                    data: "extern_id",
                },
                {
                    data: "phone",
                },
                {
                    data: "lead_name"
                },
                {
                    data: "agent"
                },
                {
                    data: "last_pass_time",
                },
                {
                    data: "last_pass_dispo",
                },
                {
                    data: "campaign_name",
                },
                {
                    data: "published",
                    render: function ( data, type, row ) {
                        return `
                        <div class="form-check form-switch">
                            <input class="form-check-input published" name="published" value="${ row.id }" type="checkbox" role="switch" ${ row.published ? 'checked' : '' } id="published_${ row.id }">
                        </div>`
                    }
                }
            ],
            columnDefs: [
                {
                    visible: false,
                    targets: [ 0 ],
                },
                {
                    orderable: false,
                    targets: [ 0, 3 ],
                },
            ],
            destroy: true,
            orderable: true,
            lengthMenu: [ 10, 20, 50, 100, 200, 500 ],
            orderCellsTop: true,
            paging: true,
            processing: true,
            searching: false,
            serverSide: true,
            stateSave: false,
            stripeClasses: [],
            pageLength: 50,
            sorting: [ [ 5, "desc" ] ],
        } )
    }

    fetch_detail_data ( e ) {
        e.preventDefault()

        let url = `${ this.dailer_detail_data_url }?${ new URLSearchParams(
            this.filters
        ).toString() }`

        console.log( url )
    }

    change_published_status ( e ) {
        const id = e.currentTarget.value,
            _SELF = this
        $.ajax( {
            url: _SELF.update.replace( '#id', id )
        } )
    }
}

window.Engage = Engage
