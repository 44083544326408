class EngageSettings {
    constructor(settings) {
        this.init();
    }

    init() {
        this.cacheDOM();
        this.bindEvents();
        this.initSelect2();
    }

    cacheDOM() {
        this.$campaigns = $("#campaigns");
    }

    bindEvents() {}

    initSelect2() {
        this.$campaigns.select2({
            allowClear: true,
            theme: "bootstrap-5",
            width: "100%",
            placeholder: "Select Campaigns",
            multiple: true,
        });
    }
}

window.EngageSettings = EngageSettings;
